import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/Footer/Footer"

export const query = graphql`
  query ServiceQuery($id: Int) {
    strapiService(strapiId: { eq: $id }) {
      strapiId
      title
      description
      content
    }
  }
`

const Service = ({ data }) => {
  const serviceData = data.strapiService
  const title = serviceData.title ? serviceData.title : ''
  const description = serviceData.description ? serviceData.description : ''
  const content = serviceData.content ? serviceData.content : ''

  const returnStyles = {
    width: "100%"
  }

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div className="grid-container island page-template">
            <SEO
              title={title}
              description={description}
            />
            <div className="island-nav">
              <div className="island-nav-btn-wrap return-to-wrap" style={returnStyles}>
                <AniLink
                  fade
                  to={`/services`}
                  className="return-to"
                >
                  Return to Services
                </AniLink>
              </div>
            </div>
            {/*<Img
              fluid={article.image.childImageSharp.fluid}
            />*/}
            <h1>{title}</h1>
            <ReactMarkdown children={content} />
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default Service
