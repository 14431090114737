import React from "react"
import Copyright from "./Copyright"
// import ContactFormLauncher from "../Contact/ContactFormLauncher"

const Footer = () => (
  <footer id="footer">
    <Copyright />
  </footer>
)

export default Footer

